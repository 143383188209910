import { observable, configure, action, computed } from 'mobx';
import { Item, Product, Bundle } from '../types';
import Products from '../services/Products';

configure({ enforceActions: 'observed' });

interface ProductItems {
  [key: string]: Array<Item>;
  Shirt: Array<Item>;
  Tie: Array<Item>;
  'Vest,Cummerbund': Array<Item>;
  Shoe: Array<Item>;
  'Pocket Square': Array<Item>;
  Socks: Array<Item>;
  'Belt,Suspenders': Array<Item>;
  'Lapel Pin': Array<Item>;
  Cufflinks: Array<Item>;
  'Tie Bar': Array<Item>;
  'Swatch (accessory)': Array<Item>;
}

interface CachedItemsByCategory {
  [index: string]: Array<Item>;
  'jacket-and-pants': Array<Bundle>;
  shirt: Array<Item>;
  tie: Array<Item>;
  'vest-and-cummerbund': Array<Item>;
  shoes: Array<Item>;
  'pocket-square': Array<Item>;
  socks: Array<Item>;
  'belt-and-suspenders': Array<Item>;
  'lapel-pin': Array<Item>;
  cufflinks: Array<Item>;
  'Tie Bar': Array<Item>;
  'Swatch (accessory)': Array<Item>;
  style: Array<Item>;
}

interface GroupedItemsCache {
  [index: string]: { [index: string]: Array<Item> };
}

const itemsStub = {
  'jacket-and-pants': [],
  shirt: [],
  tie: [],
  'vest-and-cummerbund': [],
  shoes: [],
  'pocket-square': [],
  socks: [],
  'belt-and-suspenders': [],
  'lapel-pin': [],
  cufflinks: [],
  'Tie Bar': [],
  'Swatch (accessory)': [],
  style: [],
};

type RecommendedLook = {
  id: number;
  category: string;
  isActive: boolean;
  displayable: boolean;
  displayName: string;
};

type BundleWithRecommendedLooks = Item & {
  looks?: RecommendedLook[] | undefined;
};

export const doesBundleHaveRecommendLook = (bundleId: Number, bundles: Item[]) => {
  return bundles.some((b: BundleWithRecommendedLooks) => {
    if (b.id === bundleId) {
      const looks = b.looks;

      if (Array.isArray(looks)) {
        looks?.map(
          (looksBundle) =>
            looksBundle.category === 'look' &&
            looksBundle.displayable &&
            looksBundle.isActive &&
            looksBundle.displayName?.includes('RECOMMENDED')
        );

        //Making sure the bundle is also displayable and is active
        if (looks?.length && b.displayable && b.isActive) {
          return true;
        }
      }
    }

    return false;
  });
};

class ItemStore {
  @observable itemsFetched = false;
  @observable cachedItemsByCategory: CachedItemsByCategory = itemsStub;
  @observable groupedItemsCache: GroupedItemsCache = {};
  @computed get cachedProducts() {
    return [
      ...this.cachedItemsByCategory.shirt,
      ...this.cachedItemsByCategory.tie,
      ...this.cachedItemsByCategory['vest-and-cummerbund'],
      ...this.cachedItemsByCategory.shoes,
      ...this.cachedItemsByCategory['pocket-square'],
      ...this.cachedItemsByCategory.socks,
      ...this.cachedItemsByCategory['belt-and-suspenders'],
      ...this.cachedItemsByCategory['lapel-pin'],
      ...this.cachedItemsByCategory.cufflinks,
      ...this.cachedItemsByCategory['Tie Bar'],
      ...this.cachedItemsByCategory.style,
    ];
  }
  @computed get cachedBundles() {
    return this.cachedItemsByCategory['jacket-and-pants'];
  }

  @computed get cachedBundlesWithRecommendedLooks() {
    return this.cachedItemsByCategory['style'];
  }

  @action cacheBundles = (bundles: Item[]) => (this.cachedItemsByCategory['jacket-and-pants'] = bundles);

  @action cacheBundlesWithRecommendLooks = (bundles: Item[]) => {
    return (this.cachedItemsByCategory['style'] = bundles.filter((b: BundleWithRecommendedLooks) =>
      doesBundleHaveRecommendLook(b.id, bundles)
    ));
  };

  @action cacheProducts = (products: Array<Item>) => {
    // Create a partition object with keys of each category.
    const productsPartition = products.reduce(
      (acc: ProductItems, p: Product) => {
        // attempt to find if iterated product.category
        // string is included in one of the partition key strings
        const found = Object.keys(acc).find((a: string) =>
          Boolean(p && p.category && a.toLowerCase().includes(p.category.toLowerCase()))
        );

        // if product is found with category matching
        // a partition key, append it to the key's array
        if (found) {
          return {
            ...acc,
            [`${found}`]: [...acc[found], p],
          };
        }
        return acc;
      },
      {
        Shirt: [],
        Tie: [],
        'Vest,Cummerbund': [],
        Shoe: [],
        'Pocket Square': [],
        Socks: [],
        'Belt,Suspenders': [],
        'Lapel Pin': [],
        Cufflinks: [],
        'Tie Bar': [],
        'Swatch (accessory)': [],
      }
    );

    this.cachedItemsByCategory['shirt'] = productsPartition.Shirt;
    this.cachedItemsByCategory['tie'] = productsPartition.Tie;
    this.cachedItemsByCategory['vest-and-cummerbund'] = productsPartition['Vest,Cummerbund'];
    this.cachedItemsByCategory['shoes'] = productsPartition.Shoe;
    this.cachedItemsByCategory['pocket-square'] = productsPartition['Pocket Square'];
    this.cachedItemsByCategory['socks'] = productsPartition.Socks;
    this.cachedItemsByCategory['belt-and-suspenders'] = productsPartition['Belt,Suspenders'];
    this.cachedItemsByCategory['lapel-pin'] = productsPartition['Lapel Pin'];
    this.cachedItemsByCategory['cufflinks'] = productsPartition.Cufflinks;
    this.cachedItemsByCategory['Tie Bar'] = productsPartition['Tie Bar'];
    this.cachedItemsByCategory['Swatch (accessory)'] = productsPartition['Swatch (accessory)'];
  };

  @action
  cacheGroupedItems = (category: string, key: string, items: Array<Item>) =>
    (this.groupedItemsCache = {
      ...this.groupedItemsCache,
      [category]: { ...this.groupedItemsCache[category], [key]: items },
    });

  @action
  fetchAndCache = async () => {
    try {
      const [getBundlesResponse, getProductsResponse] = await Promise.all([
        Products.getBundles(),
        Products.getProductsV2(),
      ]);

      if (getBundlesResponse.status !== 200 && getBundlesResponse.status !== 201) {
        throw new Error(getBundlesResponse.statusText);
      }

      const bundles = (await getBundlesResponse.json()).bundles;
      this.cacheBundles(bundles);
      this.cacheBundlesWithRecommendLooks(bundles);

      if (getProductsResponse.status !== 200 && getProductsResponse.status !== 201) {
        throw new Error(getProductsResponse.statusText);
      }

      this.cacheProducts((await getProductsResponse.json()).products);

      this.itemsFetched = true;
    } catch (e) {
      var errorMessage = 'Failed to load items.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw new Error(errorMessage);
    }
  };

  @action
  destroy = () => (this.cachedItemsByCategory = itemsStub);
}

const singleton = new ItemStore();
export default singleton;

import { observer } from 'mobx-react';

import { useLookBuilderState } from './context';
import PreviewStore from '../../../stores/look-builder/PreviewStore';
import SideCarStore from '../../../stores/look-builder/SideCarStore';
import { formatCurrency } from '../../../utils/utils';
import { STYLE } from '../../../constants/categories';

type TitleBarProps = {
  displayLookCost?: boolean;
};

const TitleBar = (props: TitleBarProps) => {
  const { builderAction, htoFlow } = useLookBuilderState();
  let title;
  let subTitle;
  let shouldDisplayCost = false;

  // this is a bit fugly, but as far as I understand, this is supposed to be temporary
  if (htoFlow && SideCarStore.category !== STYLE) {
    title = <h2 className="text-h3">{'Home Try-On'}</h2>;
  } else if (
    (htoFlow && SideCarStore.category === STYLE) ||
    (builderAction === 'create' && SideCarStore.category === STYLE)
  ) {
    title = <h2 className="text-h2-display pb-8">{'Choose a style to get started.'}</h2>;
    subTitle = 'You can customize your style on the next screen.';
  } else if (builderAction === 'edit' && SideCarStore.category === STYLE) {
    title = <h2 className="text-h2-display pb-8">{'Change entire style.'}</h2>;
    subTitle = 'You can customize your style on the next screen.';
  } else if (SideCarStore.car === 'list') {
    title = <h2 className="text-h3">{PreviewStore.lookName}</h2>;
    shouldDisplayCost = true;
  }

  if (!title && !subTitle) {
    return null;
  }

  return (
    <div className="flex items-center">
      <div className="flex flex-col">
        {title}
        {subTitle && <p className="mb-24 text-gray-dark">{subTitle}</p>}
      </div>

      {shouldDisplayCost && props?.displayLookCost && (
        <>
          <span className="LookBuilder__price1 mx-16">-</span>
          <span className="LookBuilder__price1 text-sm text-gray-darker">{formatCurrency(PreviewStore.getCost())}</span>
        </>
      )}
    </div>
  );
};

export default observer(TitleBar);

import { Transition } from '@headlessui/react';
import { observer } from 'mobx-react';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { useLookBuilderState } from '../context';
import ListItem from './ListItem';
import PreviewStore from '../../../../stores/look-builder/PreviewStore';
import GalleryStore from '../../../../stores/look-builder/GalleryStore';
import { lookBuilderViewedTrack, lookBuilderResetTrack } from '../../../../utils/metrics';
import { isCategoryValidInCurrentFlow, isItemValidInCurrentFlow } from '../../../utils/utils';
import { STYLE } from '../../../../constants/categories';
import IconArrowRight from '../../../../components/IconArrowRight';
import SideCarStore from '../../../../stores/look-builder/SideCarStore';

type Props = {
  animateDirection?: string;
  displayLookCost?: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  isSubmitting?: boolean;
  mediaQueryXsMatches?: boolean;
  setAnimateDirection?: Dispatch<SetStateAction<string>>;
};

const List = (props: Props) => {
  const { htoFlow, builderAction } = useLookBuilderState();

  useEffect(() => {
    lookBuilderViewedTrack('list');

    // if user comes back to list page from details page, we don't want to auto
    // scroll to last tracked gallery item if they go back to the gallery from here
    GalleryStore.resetTrackedGalleryItemElement();
  }, []);

  return (
    <Transition
      appear
      show
      enter="transition duration-500"
      enterFrom={`opacity-0 ${
        props.mediaQueryXsMatches ? '' : props.animateDirection === 'right' ? 'translate-x-[-50%]' : 'translate-x-[50%]'
      }`}
      enterTo="opacity-100"
    >
      <div data-testid="lookbuilder-list" className="flex grow flex-col justify-center py-32">
        <div>
          {PreviewStore.lookPreview
            .filter(
              (l) => isCategoryValidInCurrentFlow(l.category) && isItemValidInCurrentFlow(l) && l.category !== STYLE
            )
            .map((previewItem) => {
              // need to check for active product per previewItem and populate
              return (
                <ListItem
                  setAnimateDirection={props.setAnimateDirection}
                  key={previewItem.id}
                  category={previewItem.category}
                  product={previewItem.activeItem}
                />
              );
            })}
        </div>

        {props.displayLookCost && (
          <div className="LookBuilder__price2 my-32 flex justify-between pl-64 pr-16">
            <h5 className="text-h5 ml-16">Total</h5>
            <span className="text-gray-darker">
              $
              {PreviewStore.lookPreview.reduce((acc, previewItem) => {
                if (!previewItem.activeItem) {
                  return acc;
                }

                return acc + previewItem.activeItem!.cost!;
              }, 0)}
            </span>
          </div>
        )}

        <div
          className="sticky bottom-[32px] z-[1000] grid gap-4 bg-white p-4 shadow-2xl xl:bottom-[2vh]"
          style={{ gridTemplateColumns: 'auto 1fr' }}
        >
          <button
            className="tracker-cta-look_builder-cancel-20200508-111100 btn btn-default-outline px-16 py-16 sm:px-32 lg:py-32"
            onClick={() => props.handleClose()}
          >
            Cancel
          </button>
          <button
            className="tracker-cta-look_builder-save-20200508-111100 btn btn-info px-0 py-16 lg:py-32"
            onClick={() => props.handleSubmit()}
            disabled={props.isSubmitting || PreviewStore.productsAndBundle.length === 0}
          >
            {props.isSubmitting ? 'Saving...' : htoFlow ? 'Save Home Try-On' : 'Save Look'}
          </button>
        </div>
        {builderAction === 'edit' && (
          <div
            className="group"
            onClick={() => {
              PreviewStore.reset();
              lookBuilderResetTrack();
            }}
          >
            <button
              className="mt-32 flex flex-row items-end bg-white p-16 text-start transition ease-out md:justify-around noMouse:active:scale-[.95] mouse:group-hover:scale-[.98] mouse:group-hover:bg-brand-lighter mouse:group-hover:shadow-gallery-item"
              onClick={() => {
                props.setAnimateDirection && props.setAnimateDirection('left');
                window.scrollTo(0, 0);
                SideCarStore.set({
                  car: 'gallery',
                  category: 'style',
                  id: null,
                });
              }}
            >
              <div className="w-full sm:max-w-[65%] md:max-w-[55%]">
                <span className="mb-8 block font-bold text-black">MORE STYLES</span>
                <p className="text-sm md:text-base">
                  You can never have too many options. Start over to design a new look.
                </p>
                <div className="text-sm mt-8 flex flex-initial flex-row items-center font-bold text-brand-darker md:text-base">
                  <p className="text-sm flex flex-initial flex-row items-center font-bold md:text-base">
                    START OVER <IconArrowRight className="ml-8 text-brand-darker" />
                  </p>
                </div>
              </div>
              <div className="relative top-16 ml-4 hidden sm:block">
                <img
                  src="https://gentux.imgix.net/models2x.png?auto=format"
                  alt="Three models wearing suits"
                  className="w-full md:max-h-[110px]"
                />
              </div>
            </button>
          </div>
        )}
      </div>
    </Transition>
  );
};

export default observer(List);

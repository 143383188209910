import PreviewLayer from '../preview/PreviewLayer';
import PlaceholderImage from '../preview/PlaceholderImage';
import { lookLayers } from '../../../utils/utils';
import { Item } from '@root/src/types';
import 'src/look-builder/components/look-builder/preview/LookPreview.css';

type GalleryItemImageProps = {
  item: Item;
};

const GalleryItemImage = ({ item }: GalleryItemImageProps) => {
  const layers = lookLayers([item]);

  return (
    <div style={{ position: 'relative', zIndex: 0 }}>
      <div className="LookPreview GalleryItem jacket pant">
        <PlaceholderImage />
        {layers.map((product: Item) => (
          <PreviewLayer key={product.id} sideCarCategory={product.type!} product={product} previewProducts={layers} />
        ))}
      </div>
    </div>
  );
};

export default GalleryItemImage;

import { Member, MemberOrderDetailsById, Order } from '../types';
import auth from '../services/Auth';
import { isParticipant } from './utils';

export const getAttendees = (members: Member[]) => members.filter((m) => isParticipant(m.role!.name!));

export const formatCart = (members: Member[]) =>
  members.map((m) => ({
    ...m,
    productToMember: m
      .productToMember!.filter((product) => !product.isPaidFor && !product.isDeleted)
      .reduce((acc, product) => {
        if (product.product!.category !== 'Jacket' && product.product!.category !== 'Pant') {
          return [...acc, product];
        } else if (product.product!.category.toLowerCase() === 'jacket') {
          return [
            ...acc,
            {
              isPaidFor: product.isPaidFor,
              product: {
                ...m.role!.bundles![0],
              },
            },
          ];
        }
        return acc;
      }, []),
  }));

export const getMemberCartTotal = (member: Member) => {
  if (!member.productToMember) {
    throw new Error(`Attempted to get cart total without member's cart items (${member.id})`);
  }

  return member.productToMember.reduce((total, cartItem) => {
    if (cartItem.isPaidFor) {
      return total;
    }

    return total + Number(cartItem.product.cost ?? 0);
  }, 0);
};

export const getItemsCost = (membersToPayFor: Member[]) =>
  membersToPayFor.reduce((cost, member) => cost + getMemberCartTotal(member), 0);

export const getTotalDiscount = (membersToPayFor: Member[], memberOrderDetails: MemberOrderDetailsById) =>
  membersToPayFor.reduce(
    (discount, member) =>
      memberOrderDetails[member.id!] ? discount + memberOrderDetails[member.id!].discount : discount,
    0
  );

export const getShippingCost = (membersToPayFor: Member[], memberOrderDetails: MemberOrderDetailsById) =>
  membersToPayFor.reduce(
    (shippingCost, member) =>
      memberOrderDetails[member.id!] ? shippingCost + memberOrderDetails[member.id!].shipping_cost : shippingCost,
    0
  );

export const getDamageFee = (membersToPayFor: Member[], memberOrderDetails: MemberOrderDetailsById) =>
  membersToPayFor.reduce(
    (damageFee, member) =>
      memberOrderDetails[member.id!] ? damageFee + memberOrderDetails[member.id!].damage_waiver_fee : damageFee,
    0
  );

export const getCurrentMember = (members: Member[]) => members.find((m: Member) => m.customer!.id === auth.userId());

export const getOtherMembers = (members: Member[]) =>
  members.filter(
    (member) => member.customer!.email!.toLowerCase() !== auth.user().email.toLowerCase() && !member.isPaid
  );

export const getAttendeesWithNonEmptyCart = (members: Member[]) =>
  members.filter((m) => isParticipant(m.role!.name!) && m.productToMember!.length > 0);

export const getUnPaidMembers = (members: Member[]) => members.filter((m) => !m.isPaid);

export const orderHasShipped = (order: Order) => order.shipment && order.shipment!.status === 'SHIPPED';

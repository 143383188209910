import { useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * Adds scroll to the top to a component.
 */

type Props = {
  children?: JSX.Element;
};

const ScrollToTopWrapper = ({ children }: Props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children ?? null;
};

export default ScrollToTopWrapper;

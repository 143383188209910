import {
  JACKET_AND_PANTS,
  SHIRT,
  TIE,
  VEST_AND_CUMMERBUND,
  POCKET_SQUARE,
  LAPEL_PIN,
  CUFFLINKS,
  BELT_AND_SUSPENDERS,
  SHOES,
  SOCKS,
} from '../../../constants/categories';
import IconJacket from '../../../components/IconJacket';
import IconShirt from '../../../components/IconShirt';
import IconTie from '../../../components/IconTie';
import IconVest from '../../../components/IconVest';
import IconPocketSquare from '../../../components/IconPocketSquare';
import IconLapelPin from '../../../components/IconLapelPin';
import IconCufflinks from '../../../components/IconCufflinks';
import IconBelt from '../../../components/IconBelt';
import IconShoe from '../../../components/IconShoe';
import IconSocks from '../../../components/IconSocks';

interface Props extends React.HTMLProps<HTMLOrSVGElement> {
  category: string;
}

const CategoryIcon = (props: Props) => {
  const { category, ...newProps } = props;
  switch (category) {
    case JACKET_AND_PANTS:
      return <IconJacket {...newProps} />;
    case SHIRT:
      return <IconShirt {...newProps} />;
    case TIE:
      return <IconTie {...newProps} />;
    case VEST_AND_CUMMERBUND:
      return <IconVest {...newProps} />;
    case POCKET_SQUARE:
      return <IconPocketSquare {...newProps} />;
    case LAPEL_PIN:
      return <IconLapelPin {...newProps} />;
    case CUFFLINKS:
      return <IconCufflinks {...newProps} />;
    case BELT_AND_SUSPENDERS:
      return <IconBelt {...newProps} />;
    case SHOES:
      return <IconShoe {...newProps} />;
    case SOCKS:
      return <IconSocks {...newProps} />;
    default:
      return <></>;
  }
};

export default CategoryIcon;
